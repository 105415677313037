<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('accounts_routing.accounts_routing') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="card card-custom">
        <div class="card-body">
          <b-tabs content-class="mt-3" pills card vertical>
            <b-tab :title="$t('accounts_routing.purchases_accounts_routing')" active>

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.suppliers') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.suppliers.type_routing" @change="onChangeType('suppliers')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.suppliers.type_routing == 1"
                               v-model="data.suppliers.account"
                               @input="updateValue('suppliers', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.suppliers.account_id"
                              @input="updateValueTree('suppliers', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>

                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                    </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.purchases') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.purchases.type_routing" @change="onChangeType('purchases')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.purchases.type_routing == 1"
                               v-model="data.purchases.account"
                               @input="updateValue('purchases', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.purchases.account_id"
                              @input="updateValueTree('purchases', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.return_purchase') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.return_purchase.type_routing" @change="onChangeType('return_purchase')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>

                  <multiselect v-if="data.return_purchase.type_routing == 1"
                               v-model="data.return_purchase.account"
                               @input="updateValue('return_purchase', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.return_purchase.account_id"
                              @input="updateValueTree('return_purchase', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>

              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.tax_purchase') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.tax_purchase.type_routing" @change="onChangeType('tax_purchase')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>

                  <multiselect v-if="data.tax_purchase.type_routing == 1"
                               v-model="data.tax_purchase.account"
                               @input="updateValue('tax_purchase', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.tax_purchase.account_id"
                              @input="updateValueTree('tax_purchase', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.discount_purchase') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.discount_purchase.type_routing" @change="onChangeType('discount_purchase')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>

                  <multiselect v-if="data.discount_purchase.type_routing == 1"
                               v-model="data.discount_purchase.account"
                               @input="updateValue('discount_purchase', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.discount_purchase.account_id"
                              @input="updateValueTree('discount_purchase', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.sales_accounts_routing')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.clients') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.clients.type_routing" @change="onChangeType('clients')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.clients.type_routing == 1"
                               v-model="data.clients.account"
                               @input="updateValue('clients', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.clients.account_id"
                              @input="updateValueTree('clients', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.sales') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.sales.type_routing" @change="onChangeType('sales')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.sales.type_routing == 1"
                               v-model="data.sales.account"
                               @input="updateValue('sales', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.sales.account_id"
                              @input="updateValueTree('sales', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.returns') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.returns.type_routing" @change="onChangeType('returns')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.returns.type_routing == 1"
                               v-model="data.returns.account"
                               @input="updateValue('returns', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.returns.account_id"
                              @input="updateValueTree('returns', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.discount_allowed') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.discount_allowed.type_routing" @change="onChangeType('discount_allowed')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[1]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.discount_allowed.type_routing == 1"
                               v-model="data.discount_allowed.account"
                               @input="updateValue('discount_allowed', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.discount_allowed.account_id"
                              @input="updateValueTree('discount_allowed', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.sales_credit') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.sales_credit.type_routing" @change="onChangeType('sales_credit')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[3]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.sales_credit.type_routing == 1"
                               v-model="data.sales_credit.account"
                               @input="updateValue('sales_credit', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.sales_credit.account_id"
                              @input="updateValueTree('sales_credit', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.adjustment_routing') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.adjustment_routing.type_routing" @change="onChangeType('adjustment_routing')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.adjustment_routing.type_routing == 1"
                               v-model="data.adjustment_routing.account"
                               @input="updateValue('adjustment_routing', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.adjustment_routing.account_id"
                              @input="updateValueTree('adjustment_routing', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row mt-10">
                <div class="col-lg-12 mb-5 mt-5">
                  <h5>{{ $t('accounts_routing.tax_sales') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.tax_sales.type_routing" @change="onChangeType('tax_sales')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[2]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.tax_sales.type_routing == 1"
                               v-model="data.tax_sales.account"
                               @input="updateValue('tax_sales', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.tax_sales.account_id"
                              @input="updateValueTree('tax_sales', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.inventory_accounts_routing')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.inventory_accounts_routing') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.inventory_accounts_routing.type_routing" @change="onChangeType('inventory_accounts_routing')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.inventory_accounts_routing.type_routing == 1"
                               v-model="data.inventory_accounts_routing.account"
                               @input="updateValue('inventory_accounts_routing', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.inventory_accounts_routing.account_id"
                              @input="updateValueTree('inventory_accounts_routing', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.treasury_accounts_routing')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.treasury_accounts_routing') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.treasury_accounts_routing.type_routing" @change="onChangeType('treasury_accounts_routing')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.treasury_accounts_routing.type_routing == 1"
                               v-model="data.treasury_accounts_routing.account"
                               @input="updateValue('treasury_accounts_routing', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.treasury_accounts_routing.account_id"
                              @input="updateValueTree('treasury_accounts_routing', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.payable_cheque_account')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.payable_cheque_account') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.payable_cheque_account.type_routing" @change="onChangeType('payable_cheque_account')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>

                  <multiselect v-if="data.payable_cheque_account.type_routing == 1"
                               v-model="data.payable_cheque_account.account"
                               @input="updateValue('payable_cheque_account', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.payable_cheque_account.account_id"
                              @input="updateValueTree('payable_cheque_account', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.receivable_cheque_account')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.receivable_cheque_account') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.receivable_cheque_account.type_routing" @change="onChangeType('receivable_cheque_account')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.receivable_cheque_account.type_routing == 1"
                               v-model="data.receivable_cheque_account.account"
                               @input="updateValue('receivable_cheque_account', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.receivable_cheque_account.account_id"
                              @input="updateValueTree('receivable_cheque_account', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.incomes_expenses_account')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.incomes_expenses_account') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.incomes_expenses.type_routing" @change="onChangeType('incomes_expenses')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.incomes_expenses.type_routing == 1"
                               v-model="data.incomes_expenses.account"
                               @input="updateValue('incomes_expenses', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.incomes_expenses.account_id"
                              @input="updateValueTree('incomes_expenses', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.banks_account')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.banks_account') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.banks.type_routing" @change="onChangeType('banks')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.banks.type_routing == 1"
                               v-model="data.banks.account"
                               @input="updateValue('banks', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.banks.account_id"
                              @input="updateValueTree('banks', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.financial_accounts_routing')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.incomes') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.incomes.type_routing" @change="onChangeType('incomes')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.incomes.type_routing == 1"
                               v-model="data.incomes.account"
                               @input="updateValue('incomes', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.incomes.account_id"
                              @input="updateValueTree('incomes', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.expenses') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.routing_type') }}</label>
                  <div class="input-group">
                    <select name="" v-model="data.expenses.type_routing" @change="onChangeType('expenses')" class="custom-select" :class="validation && validation.type_routing ? 'is-invalid' : ''">
                      <option v-for="row in types[0]" :value="row.id" :key="row.id">
                        {{ row.title }}
                      </option>
                    </select>
                    <span v-if="validation && validation.type_routing" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.type_routing[0] }}
                                    </span>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.account') }}<span class="text-danger">*</span></label>
                  <multiselect v-if="data.expenses.type_routing == 1"
                               v-model="data.expenses.account"
                               @input="updateValue('expenses', $event)"
                               :placeholder="$t('accounts_routing.account')"
                               label="label"
                               track-by="id"
                               :options="accounts_list"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getAccounts($event)">
                  </multiselect>
                  <treeselect v-else
                              :options="accounts_parents_list"
                              :load-options="loadOptions"
                              :multiple="false"
                              :value="data.expenses.account_id"
                              @input="updateValueTree('expenses', $event)"
                              :searchable="true"
                              :class="validation && validation.account_id ? 'is-invalid' : ''"
                              :show-count="true"
                              :no-children-text="$t('No_sub_options')"
                              :no-options-text="$t('No_options_available')"
                              :no-results-text="$t('No_results_found')"
                              :placeholder="$t('Select')">
                  </treeselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('accounts_routing.requisition_journal_settings')">

              <div class="form-group row">
                <div class="col-lg-12 mb-5">
                  <h5>{{ $t('accounts_routing.requisition_journal_settings') }}</h5>
                  <hr>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.inbound_requisition') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.inbound_requisition.account_id"-->
                  <!--                                            @input="updateValue('inbound_requisition', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.inbound_requisition.account"
                      @input="updateValue('inbound_requisition', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.outbound_requisition') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.outbound_requisition.account_id"-->
                  <!--                                            @input="updateValue('outbound_requisition', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.outbound_requisition.account"
                      @input="updateValue('inbound_requisition', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.stocktaking_outbound') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.stocktaking_outbound.account_id"-->
                  <!--                                            @input="updateValue('stocktaking_outbound', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.stocktaking_outbound.account"
                      @input="updateValue('stocktaking_outbound', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.stocktaking_inbound') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.stocktaking_inbound.account_id"-->
                  <!--                                            @input="updateValue('stocktaking_inbound', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.stocktaking_inbound.account"
                      @input="updateValue('stocktaking_inbound', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.invoice') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.invoice.account_id"-->
                  <!--                                            @input="updateValue('invoice', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.invoice.account"
                      @input="updateValue('invoice', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.refund_receipt') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.refund_receipt.account_id"-->
                  <!--                                            @input="updateValue('refund_receipt', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.refund_receipt.account"
                      @input="updateValue('refund_receipt', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.credit_note') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.credit_note.account_id"-->
                  <!--                                            @input="updateValue('credit_note', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.credit_note.account"
                      @input="updateValue('credit_note', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.pos_shift_outbound') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.pos_shift_outbound.account_id"-->
                  <!--                                            @input="updateValue('pos_shift_outbound', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.pos_shift_outbound.account"
                      @input="updateValue('pos_shift_outbound', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('accounts_routing.pos_shift_inbound') }}<span class="text-danger">*</span></label>
                  <!--                                    <treeselect-->
                  <!--                                            :options="accounts_list"-->
                  <!--                                            :load-options="loadOptions"-->
                  <!--                                            :multiple="false"-->
                  <!--                                            :value="data.pos_shift_inbound.account_id"-->
                  <!--                                            @input="updateValue('pos_shift_inbound', $event)"-->
                  <!--                                            :searchable="true"-->
                  <!--                                            :class="validation && validation.account_id ? 'is-invalid' : ''"-->
                  <!--                                            :show-count="true"-->
                  <!--                                            :no-children-text="$t('No_sub_options')"-->
                  <!--                                            :no-options-text="$t('No_options_available')"-->
                  <!--                                            :no-results-text="$t('No_results_found')"-->
                  <!--                                            :placeholder="$t('Select')">-->
                  <!--                                    </treeselect>-->
                  <multiselect
                      v-model="data.pos_shift_inbound.account"
                      @input="updateValue('pos_shift_inbound', $event)"
                      :placeholder="$t('accounts_routing.account')"
                      label="label"
                      track-by="id"
                      :options="accounts_list"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <span v-if="validation && validation.account_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.account_id[0] }}
                                </span>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-accounts-routing",

  data() {
    return {
      mainRoute: 'accounting/accounts-routing',
      mainRouteDependency: 'base/dependency',

      data: {
        suppliers: {
          key: 'suppliers',
          tab_key: "purchases_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        purchases: {
          key: 'purchases',
          tab_key: "purchases_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        return_purchase: {
          key: 'return_purchase',
          tab_key: "purchases_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },

        clients: {
          key: 'clients',
          tab_key: "sales_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        sales: {
          key: 'sales',
          tab_key: "sales_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        returns: {
          key: 'returns',
          tab_key: "sales_accounts_routing",
          account_id: null,
          type_routing: 0,
        },
        discount_allowed: {
          key: 'discount_allowed',
          tab_key: "sales_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        sales_credit: {
          key: 'sales_credit',
          tab_key: "sales_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        adjustment_routing: {
          key: 'adjustment_routing',
          tab_key: "sales_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        inventory_accounts_routing: {
          key: 'inventory_accounts_routing',
          tab_key: "inventory_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        treasury_accounts_routing: {
          key: 'treasury_accounts_routing',
          tab_key: "treasury_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        payable_cheque_account: {
          key: 'payable_cheque_account',
          tab_key: "payable_cheque_account",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        receivable_cheque_account: {
          key: 'receivable_cheque_account',
          tab_key: "receivable_cheque_account",
          account_id: null,
          account: null,
          type_routing: 0,
        },


        inbound_requisition: {
          key: 'inbound_requisition',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        outbound_requisition: {
          key: 'outbound_requisition',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        stocktaking_outbound: {
          key: 'stocktaking_outbound',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        stocktaking_inbound: {
          key: 'stocktaking_inbound',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        invoice: {
          key: 'invoice',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        refund_receipt: {
          key: 'refund_receipt',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        credit_note: {
          key: 'credit_note',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        pos_shift_outbound: {
          key: 'pos_shift_outbound',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        pos_shift_inbound: {
          key: 'pos_shift_inbound',
          tab_key: "requisition_journal_settings",
          account_id: null,
          account: null,
          type_routing: 1,
        },
        incomes: {
          key: 'incomes',
          tab_key: "financial_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        expenses: {
          key: 'expenses',
          tab_key: "financial_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        banks: {
          key: 'banks',
          tab_key: "banks_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        incomes_expenses: {
          key: 'incomes_expenses',
          tab_key: "incomes_expenses_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        tax_sales: {
          key: 'tax_sales',
          tab_key: "sales_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        tax_purchase: {
          key: 'tax_purchase',
          tab_key: "purchases_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
        discount_purchase: {
          key: 'discount_purchase',
          tab_key: "purchases_accounts_routing",
          account_id: null,
          account: null,
          type_routing: 0,
        },
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,

      accounts_list: [],
      accounts_parents_list: [],
      types: {},
      innerList: []
    };
  },

  methods: {

    save() {
      this.create();
    },

    create() {
      let _data = [];
      let that = this;
      Object.keys(that.data).forEach(function (key) {
        _data.push(that.data[key]);
      });
      ApiService.post(`${this.mainRoute}`, {
        ..._data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    async getData() {
      ApiService.get(this.mainRoute).then((response) => {
        this.accounts_list = [];
        response.data.data.map((row) => {
          this.data[row.key] = row;
          this.accounts_list.push(row.account);
        });
      });
    },

    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts_list = response.data.data;
        });
    },
    getAccountParents() {
      ApiService.get(this.mainRouteDependency + `/account_parents`).then((response) => {
        this.accounts_parents_list = response.data.data;
      });
    },
    getTypes() {
      ApiService.get(this.mainRouteDependency + `/type_routing`).then((response) => {
        this.types = response.data.data;
      });
    },

    // customAccountsList(type){
    //     let _clone = this.accounts_list.map(a => {return {...a}});
    //     let _list = [];
    //     if (_clone.length > 0 && type){
    //         _list = this.customList(_clone, type);
    //         // _list[0].then((result)=>{
    //         //     this.innerList = result.map(a => {return {...a}});
    //         // });
    //
    //        // console.log(_list , type);
    //     }
    //
    //     return _list;
    // },
    // async customList(_clone, type){
    //     let _list_2 = [];
    //         await _clone.forEach((row)=>{
    //         if (row){
    //             if (type == 1){
    //                 if (row.type_account == 2){
    //                     row.isDisabled = false;
    //                 }else {
    //                     row.isDisabled = true;
    //                 }
    //             }
    //             if (type == 2){
    //                 if (row.type_account == 1){
    //                     row.isDisabled = false;
    //                 }else {
    //                     row.isDisabled = true;
    //                 }
    //             }
    //             if (row.children && row.children.length && type){
    //                 let _children = [];
    //                 this.customList(row.children, type).then((ch)=>{
    //                         _children.push(...ch);
    //                 }, _children);
    //                 console.log(_children);
    //                 row.children = _children;
    //             }
    //
    //             _list_2.push(row);
    //
    //         }
    //
    //         });
    //     // console.log(_list);
    //     return _list_2;
    //     },
    loadOptions() {
    },

    updateValue(index, value) {
      this.data[index].account_id = value ? value.id : null;
    },
    updateValueTree(index, value) {
      this.data[index].account_id = value;
      this.data[index].account = null;
    },
    onChangeType(index) {
      this.data[index].account_id = null;
      this.data[index].account = null;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.accounts_routing")}]);

    this.getAccountParents();
    this.getTypes();

    this.getData();

  },
};
</script>